import { FcDataConfiguration } from "react-icons/fc";
import { PATH } from "../config";
import {
  FaBaby,
  FaClinicMedical,
  FaHandHoldingHeart,
  FaHospitalUser,
  FaListUl,
  FaTachometerAlt,
  FaUserMd,
  FaUserShield,
  FaVideo,
} from "react-icons/fa";
import React from "react";
import { useSelector } from "react-redux";

export const dashboardOption = [
  { value: 1, label: "Global Dashboard" },
  { value: 2, label: "Immunization" },
  { value: 3, label: "Antenatal Care" },
];

export const ROLE_POLICES = [
  {
    id: 23,
    name: "OTP Patients List",
    description: "",
    value: "sm:dashboard",
    subPolicies: null,
    path: PATH.OTP_PATIENT_LISTING,
    tags: null,
    icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
  },
  {
    id: 22,
    name: "Tally Validation",
    description: "",
    value: "sm:tally_validation",
    subPolicies: null,
    path: PATH.TALLY_VALIDATION,
    tags: null,
    icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
  },
  {
    id: 21,
    name: "Roles permissions",
    description: "",
    value: "sm:roles_permissions",
    subPolicies: null,
    path: PATH.ROLES_AND_PERMISSIONS,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    id: 20,
    name: "DHIS2 Facilities",
    description: "",
    value: "sm:dhis2_facilities",
    subPolicies: null,
    path: PATH.DHIS2_CONFIGURATION,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    id: 19,
    name: "DHIS2 requests",
    description: "",
    value: "sm:dhis2_requests",
    subPolicies: null,
    path: PATH.DHIS_REQUESTS,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    id: 17,
    name: "DHIS2 Configuration",
    description: "",
    value: "sm:dhis2_configuration",
    subPolicies: null,
    path: PATH.DHIS_CONFIGURATION,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    id: 18,
    name: "DHIS2 Upload",
    description: "",
    value: "sm:dhis2_upload",
    subPolicies: null,
    path: PATH.DHIS_UPLOAD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    id: 16,
    name: "DHIS2 Logs",
    description: "",
    value: "sm:dhis2_logs",
    subPolicies: null,
    path: PATH.DHIS_LOGS,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    id: 15,
    name: "Logging Activity",
    description: "",
    value: "sm:logging_activity",
    subPolicies: null,
    path: PATH.LOGGING_ACTIVITY,
    tags: null,
    icon: <FaListUl size="18" className="h-auto w-auto" />,
  },
  {
    id: 14,
    name: "Video Learning Centre",
    description: "",
    value: "sm:video_learning_centre",
    subPolicies: null,
    path: PATH.LEARNING_CENTRE,
    tags: null,
    icon: <FaVideo size="18" className="h-auto w-auto" />,
  },
  {
    id: 13,
    name: "Child List",
    description: "",
    value: "sm:child_list",
    subPolicies: null,
    path: PATH.CHILD_LIST,
    tags: null,
    icon: <FaBaby size="18" className="h-auto w-auto" />,
  },
  {
    id: 11,
    name: "Caregiver List",
    description: "",
    value: "sm:caregiver_list",
    subPolicies: null,
    path: PATH.CARE_GIVER_LIST,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    id: 12,
    name: "Patients List",
    description: "",
    value: "sm:patients_list",
    subPolicies: null,
    path: PATH.PATIENT_LIST,
    tags: null,
    icon: <FaHospitalUser size="18" className="h-auto w-auto" />,
  },
  {
    id: 10,
    name: "Health Worker List",
    description: "",
    value: "sm:doctor_list",
    subPolicies: null,
    path: PATH.DOCTOR_LIST,
    tags: null,
    icon: <FaUserMd size="18" className="h-auto w-auto" />,
  },
  {
    id: 9,
    name: "Admin List",
    description: "",
    value: "sm:admin_list",
    subPolicies: null,
    path: PATH.ADMIN_LIST,
    tags: null,
    icon: <FaUserShield size="18" className="h-auto w-auto" />,
  },
  {
    id: 8,
    name: "Clinic List",
    description: "",
    value: "sm:clinic_list",
    subPolicies: null,
    path: PATH.CLINIC_LIST,
    tags: null,
    icon: <FaClinicMedical size="18" className="h-auto w-auto" />,
  },
  {
    id: 7,
    name: "Dashboard",
    description: "",
    value: "sm:dashboard",
    subPolicies: null,
    path: PATH.MAIN_DASHBOARD,
    tags: null,
    icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
  },

];

export const OPTION = {
  IMMUNIZATION_STATUS: [
    { value: 0, label: "Pending" },
    { value: 1, label: "Denied " },
    { value: 2, label: "Approved " },
  ],
  IS_FACILITY_STATIC_OR_MOBILE: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No " },
  ],
  GENDERS: [
    { value: 2, label: "All Genders" },
    { value: 0, label: "Male" },
    { value: 1, label: "Female" },
  ],
  AGE: [
    { value: "", label: "All Ages" },
    { value: 1, label: "< 1 Year" },
    { value: 2, label: "< 2 Years" },
    { value: 3, label: "< 3 Years" },
    { value: 4, label: "< 4 Years" },
    { value: 5, label: "< 5 Years" },
  ],

  SECTIONS: [
    { value: 1, label: "Malaria Vaccination Detail" },
    { value: 2, label: "Malaria Detail" },
    { value: 3, label: "Visit Summary" },
    { value: 4, label: "TT Summary" },
    { value: 5, label: "Plasmodium Species" },
    { value: 6, label: "Anaemia Prevention & Treatment" },
    { value: 7, label: "PMTCT Summary" },
    { value: 8, label: "ART Prophylaxis" },
    { value: 9, label: "Syphilis Screening" },
    { value: 10, label: "Health Education" },
  ],
  GENDER: [
    { value: "", label: "All Genders" },
    { value: 0, label: "Male" },
    { value: 1, label: "Female" },
  ],

  MARITAL_STATUS: [
    { value: 0, label: "Single" },
    { value: 1, label: "Married" },
    { value: 2, label: "Divorced" },
    { value: 3, label: "Separated" },
    { value: 4, label: "Widowed" },
  ],

  MONTH: [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ],

  YEAR: [
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
    { value: 2026, label: 2026 },
    { value: 2027, label: 2027 },
    { value: 2028, label: 2028 },
    { value: 2029, label: 2029 },
    { value: 2030, label: 2030 },
  ],

  DAY: [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
  ],

  CLINIC: [
    { value: 1, label: "Kalkaal MCH" },
    { value: 2, label: "Shafici MCH" },
    { value: 3, label: "Gargar MCH" },
    { value: 4, label: "Waberi MCH" },
  ],

  CHART_TYPE: [
    { value: 1, label: "Yearly" },
    { value: 2, label: "Monthly" },
  ],

  LANGUAGES: [
    { value: 1, label: "Somali" },
    { value: 2, label: "English" },
  ],

  PATIENT_TYPE: [
    { value: "", label: "All Patients" },
    { value: 0, label: "Child" },
    { value: 1, label: "Mother" },
  ],
  REFUSED_ANOTHER_FACILITY_TYPE: [
    { value: 2, label: "Refused" },
    { value: 3, label: "Another Facility" },
  ],
  PATIENT: [
    { value: 0, label: "Child" },
    { value: 1, label: "Mother" },
  ],
  ROLE_TYPE: [
    { value: null, label: "All" },
    { value: 2, label: "Super Admin" },
    { value: 1, label: "Admin" },
    { value: 4, label: "Health Worker" },
  ],

  TABLE_NAME: [
    { value: 1, label: "Immunization" },
    // { value: 2, label: "ANC" },
  ],
  FILTER_MSG_TYPE: [
    { value: null, label: "All" },
    { value: "SMS", label: "SMS" },
    { value: "IVR", label: "IVR" },
  ],
  ANALYTIC_FILTER_OPTION: [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "7", label: "Last 7 days" },
    { value: "30", label: "Last 30 days" },
    { value: "90", label: "Last 90 days" },
    { value: "custom", label: "Custom" },
  ],
  STOCK_TYPE_FILTER_OPTIONS: [
    { value: "Stock availability", label: "Stock Availability" },
    { value: "Stock dispensed", label: "Stock Dispensed" },
    { value: "Stock received", label: "Stock Received" },
  ],
  AGE_FILTER_OPTION: [
    { value: 1, label: "Less than" },
    { value: 2, label: "Greater than" },
    { value: 3, label: "Equals to" },
    { value: 4, label: "Between" },
  ],
  DAYS_FILTER_OPTION: [
    { value: "1", label: "Years" },
    { value: "2", label: "Months" },
    { value: "3", label: "Days" },
  ],
  USER_ROLE: [
    { value: "Admin", label: "Admin" },
    { value: "ModerateAdmin", label: "Moderate Admin" },
    { value: "DHISViewer", label: "District Health Officer" },
    { value: "DHISAdmin", label: "Regional HMIS" },
    { value: "DHISAdminAndViewer", label: "HMIS Lead" },
    { value: "analyticsmanager", label: "Analytics Manager" },
  ],
  USER_ROLE_ID: [
    { value: 1, label: "Admin" },
    { value: 5, label: "District Health Officer" },
    { value: 6, label: "Regional HMIS" },
    { value: 9, label: "HMIS Lead" },
    { value: 7, label: "Analytics Manager" },
    { value: 10, label: "Moderate Admin" },
  ],
  DHIS_PUSH_STATUS: [
    { value: "Pending", label: "Pending" },
    { value: "Pushed", label: "Pushed" },
  ],
};

//true, demo mode will set Name and phone numbers to 'dummy'
//false , Data from backend
export const DemoMode = false;
export const IsStaging = true;
