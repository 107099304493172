/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import TermsandCondtitions from "./modules/TermsAndConditions";
import { getAllLocationAction } from "../redux/actions/locationAction";
import { FaBaby, FaHandHoldingHeart, FaListUl, FaTachometerAlt, FaVideo } from "react-icons/fa";
import { PATH } from "./config";
import { FcDataConfiguration } from "react-icons/fc";
const DASHBOARDS = [
  {
    name: "Global Dashboard",
    description: "",
    value: "md:globalDashboard",
    subPolicies: null,
    path: PATH.MAIN_DASHBOARD,
    tags: null,
    icon: <FaTachometerAlt size="18" className="h-auto w-auto" />,
  },
  {
    name: "Immunization",
    description: "",
    value: "md:immunization",
    subPolicies: null,
    path: PATH.IMMUNIZATION_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Antenatal Care",
    description: "",
    value: "md:antenatalCare",
    subPolicies: null,
    path: PATH.ANC_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "OPD Over Five",
    description: "",
    value: "md:overFive",
    subPolicies: null,
    path: PATH.OPD_FIVE_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "OPD Under Five",
    description: "",
    value: "md:underFive",
    subPolicies: null,
    path: PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Primary Health Unit",
    description: "",
    value: "md:primaryHealthUnit",
    subPolicies: null,
    path: PATH.PRIMARY_HEALTH_UNIT_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Hospital InPatient Register",
    description: "",
    value: "md:inPatientRegister",
    subPolicies: null,
    path: PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD,
    tags: null,
    icon: <FcDataConfiguration size="18" className="h-auto w-auto" />,
  },
  {
    name: "Posta Natal Care",
    description: "",
    value: "md:postaNatalCare",
    subPolicies: null,
    path: PATH.POSTNATAL_CARE_DASHBOARD,
    tags: null,
    icon: <FaListUl size="18" className="h-auto w-auto" />,
  },
  {
    name: "Labour Delivery Maternity",
    description: "",
    value: "md:labourDeliveryMaternity",
    subPolicies: null,
    path: PATH.LABOUR_DELIVERY_MATERNITY_DASHBOARD,
    tags: null,
    icon: <FaVideo size="18" className="h-auto w-auto" />,
  },
  {
    name: "Wash Register",
    description: "",
    value: "md:washRegister",
    subPolicies: null,
    path: PATH.WASH_REGISTER_DASHBOARD,
    tags: null,
    icon: <FaBaby size="18" className="h-auto w-auto" />,
  },
  {
    name: "Protection Monthly Reporting",
    description: "",
    value: "md:protectionMonthlyReporting",
    subPolicies: null,
    path: PATH.PROTECTION_MONTHLY_REPORTING_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "Emergency Preparedness Monthly Reporting",
    description: "",
    value: "md:emergencyPreparednessMonthlyReporting",
    subPolicies: null,
    path: PATH.EMERGENCY_PREPARENESS_MONTHLY_REPORTING_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "OTP",
    description: "",
    value: "md:otp",
    subPolicies: null,
    path: PATH.OTP_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },
  {
    name: "SC",
    description: "",
    value: "md:sc",
    subPolicies: null,
    path: PATH.SC_DASHBOARD,
    tags: null,
    icon: <FaHandHoldingHeart size="18" className="h-auto w-auto" />,
  },

];
export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  let dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllLocationAction());
  }, [])
  let policesData = useSelector((state) => state?.clinicsReducer);
  const filteredPolicies = DASHBOARDS.filter((item) =>
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.some(
      (subItem) => subItem.value === item.value
    )
  );
  return (
    <Switch>
      <Route path="/termsandconditions" component={TermsandCondtitions} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to={filteredPolicies && filteredPolicies.length > 0 && filteredPolicies?.[0]?.path} />
        // <Redirect from="/auth" to={'/'} />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
