import axios from "axios";
import { API_PATH } from "../config";
import { fi } from "date-fns/locale";

/**
 * Get Patient List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function isReminderEnabledApi(data) {
  return axios.post(`${API_PATH.PATIENT.isReminderEnabled}`, data);
}
export function getPatientListApi(filters) {
  return axios.get(API_PATH.PATIENT.getPatientList, {
    params: filters,
  });
}
export function getOtpPatientListApi(filters) {
  return axios.get(API_PATH.PATIENT.getOtpPatientList, {
    params: filters,
  });
}
export function getMotherWithAncDataApi(filters) {
  return axios.get(API_PATH.PATIENT.getMotherWithAncData, {
    params: filters,
  });
}
/**
 * Get Single Patient Details
 *
 * @param {Object} patientId
 * @return {*} Promise
 */
export function getSinglePatientDetailApi(patientId) {
  return axios.get(`${API_PATH.PATIENT.getSinglePatient}?Id=${patientId}`);
}
export function getOtpPatientDetailApi(otpAdminssionId) {
  return axios.get(`${API_PATH.PATIENT.otpAdminssionId}?otpAdminssionId=${otpAdminssionId}`);
}
export function getOtpVisitListApi(filters) {
  return axios.get(API_PATH.PATIENT.getOtpVisitList, {
    params: filters,
  });
}
export function changePatientGenderAPI(filters) {
  return axios.post(API_PATH.PATIENT.changeGender, filters);
}