/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { FaRegBell } from "react-icons/fa";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const bgImage = toAbsoluteUrl("/assets/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-primary">
              <FaRegBell sm="20" />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  Notifications
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-primary">
                  <FaRegBell sm="20" />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-0 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top py-5">
                  <span className="text-white">User Notifications</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    23 new
                  </span>
                </h4>

                <div className="bg-white py-5">
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll pr-7"
                    style={{ maxHeight: "300px", position: "relative" }}
                  >
                    <div className="d-flex align-items-center mb-0 px-5">
                      <div className="d-flex flex-column font-weight-bold">
                        <a
                          href="#"
                          className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                          New Immunization by DR. John
                        </a>
                        <span className="text-muted font-size-sm">
                          4 Hours and 13 minute ago
                        </span>
                      </div>
                    </div>
                    <div className="customDivider my-3"></div>
                    <div className="d-flex align-items-center mb-0 px-5">
                      <div className="d-flex flex-column font-weight-bold">
                        <a
                          href="#"
                          className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                          New Immunization by DR. John
                        </a>
                        <span className="text-muted font-size-sm">
                          4 Hours and 13 minute ago
                        </span>
                      </div>
                    </div>
                    <div className="customDivider my-3"></div>
                    <div className="d-flex align-items-center mb-0 px-5">
                      <div className="d-flex flex-column font-weight-bold">
                        <a
                          href="#"
                          className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                          New Immunization by DR. John
                        </a>
                        <span className="text-muted font-size-sm">
                          4 Hours and 13 minute ago
                        </span>
                      </div>
                    </div>
                    <div className="customDivider my-3"></div>
                    <div className="d-flex align-items-center mb-0 px-5">
                      <div className="d-flex flex-column font-weight-bold">
                        <a
                          href="#"
                          className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                          New Immunization by DR. John
                        </a>
                        <span className="text-muted font-size-sm">
                          4 Hours and 13 minute ago
                        </span>
                      </div>
                    </div>
                    <div className="customDivider my-3"></div>
                    <div className="d-flex align-items-center mb-0 px-5">
                      <div className="d-flex flex-column font-weight-bold">
                        <a
                          href="#"
                          className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                          New Immunization by DR. John
                        </a>
                        <span className="text-muted font-size-sm">
                          4 Hours and 13 minute ago
                        </span>
                      </div>
                    </div>
                    <div className="customDivider my-3"></div>
                    <div className="d-flex align-items-center mb-0 px-5">
                      <div className="d-flex flex-column font-weight-bold">
                        <a
                          href="#"
                          className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                          New Immunization by DR. John
                        </a>
                        <span className="text-muted font-size-sm">
                          4 Hours and 13 minute ago
                        </span>
                      </div>
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
